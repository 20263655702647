import React, { useState, useEffect, useRef } from 'react';

function SignupForm() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const emailRef = useRef(null); // Reference for the email input

  // Set API base URL based on environment
  const apiBaseURL =
    process.env.NODE_ENV === 'production'
      ? 'https://orion-prod.onrender.com/api'  // Production URL
      : 'http://localhost:5001/api';           // Development URL

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailPattern.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setError('');
    setStatus('Submitting...');

    try {
      const response = await fetch(`${apiBaseURL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccess(true);
        setStatus('Signup successful! Thank you.');
        setEmail('');
        setTimeout(() => setStatus(''), 4000);
      } else {
        setStatus('You are already signed up. All good!');
        setTimeout(() => setStatus(''), 4000);
      }
    } catch (error) {
      setStatus('Error: Could not connect to server.');
      setTimeout(() => setStatus(''), 4000);
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setStatus('');
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [success]);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <div className="flex flex-col items-center w-full">
      {!success ? (
        <>
          <form onSubmit={handleSubmit} className="flex items-center space-x-2">
            <input
              type="email"
              ref={emailRef} // Set ref to focus on this input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="px-4 py-2 bg-transparent border border-white rounded-lg text-white focus:outline-none ml-2"
              required
            />
            <button
              type="submit"
              className="bg-custom-turquoise text-white px-4 py-2 rounded-lg hover:bg-opacity-90 text-sm sm:text-base"
            >
              Join Waitlist
            </button>
          </form>
          {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
          {status && <p className="text-sm text-gray-300 mt-2">{status}</p>}
        </>
      ) : (
        <div className="text-center text-custom-turquoise font-semibold mt-4">
          Thank you for joining the waitlist! We’ll keep you updated.
        </div>
      )}
    </div>
  );
}

export default SignupForm;
