import React from 'react';

function Privacy() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-8 py-16 text-white bg-gradient-to-b from-black to-[#1c3f60]">
    <div className="max-w-3xl mx-auto p-6 pt-20">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for Liquid GTM and Orion</h1>
      <p className="mb-4">Effective Date: October 30th, 2024</p>

      <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
      <p className="mb-4">Liquid GTM and Orion ("we," "our," "us") value your privacy. This policy describes the types of information we collect, how it is used, and your rights.</p>

      <h2 className="text-xl font-semibold mb-2">2. Information We Collect</h2>
      <p className="mb-4">
        <strong>Liquid GTM Marketing Services:</strong> We collect information necessary for digital marketing services, such as contact information and engagement metrics.
      </p>
      <p className="mb-4">
        <strong>Orion Network Engagement Tool:</strong> We collect user-provided data, including LinkedIn profile data (when authorized), professional network information, and contact details. This information is used solely for Orion’s networking insights, eCard communications, and recommendations.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Purpose of Data Collection</h2>
      <p className="mb-4">Data is collected to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Provide targeted marketing services through Liquid GTM.</li>
        <li>Enhance Orion's network engagement features by personalizing recommendations, enabling eCard scheduling, and offering insights on networking opportunities.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-2">4. Data Sharing and Third-Party Services</h2>
      <p className="mb-4">
        We do not sell user data. Data may be shared with third-party service providers strictly necessary for service functionality, including hosting, analytics, and email distribution.
      </p>
      <p className="mb-4">
        LinkedIn profile data collected for Orion is protected by LinkedIn's privacy requirements, and we ensure it is only accessed with explicit user consent.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. LinkedIn API Compliance</h2>
      <p className="mb-4">Orion complies with LinkedIn's data usage policies and only accesses data authorized by the user.</p>

      <h2 className="text-xl font-semibold mb-2">6. Data Retention and Deletion</h2>
      <p className="mb-4">
        Personal data is retained as long as necessary for providing our services. Users may request data deletion at any time, particularly LinkedIn-derived data, and we will delete it within 30 days of the request.
      </p>

      <h2 className="text-xl font-semibold mb-2">7. Cookies and Tracking</h2>
      <p className="mb-4">
        Cookies are used to enhance user experience, analyze traffic, and provide insights. For Orion, cookies may track network engagement analytics to improve user experience. Users can manage cookies via their browser settings.
      </p>

      <h2 className="text-xl font-semibold mb-2">8. Security</h2>
      <p className="mb-4">We use industry-standard security measures to protect your data and prevent unauthorized access.</p>

      <h2 className="text-xl font-semibold mb-2">9. Your Rights</h2>
      <ul className="list-disc list-inside mb-4">
        <li>Access: You may request to view the data we hold about you.</li>
        <li>Deletion: You may request deletion of your data, including LinkedIn data in Orion.</li>
        <li>Consent Withdrawal: You may revoke consent for data processing at any time.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
      <p className="mb-4">For questions about this policy, contact us at info@liquidgtm.com.</p>
    </div>
    </div>
  );
}

export default Privacy;
