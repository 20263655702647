import React from 'react';

function Terms() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-8 py-16 text-white bg-gradient-to-b from-black to-[#1c3f60]">
    <div className="max-w-3xl mx-auto p-6 pt-20">
      <h1 className="text-3xl font-bold mb-4">Terms of Use for Liquid GTM and Orion</h1>
      <p className="mb-4">Effective Date: October 30th, 2024</p>
      <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">By accessing our sites, you accept these Terms of Use, applicable across all services provided by Liquid GTM and Orion.</p>

      <h2 className="text-xl font-semibold mb-2">2. Permitted Use</h2>
      <p className="mb-4">
        <strong>Liquid GTM Marketing Services:</strong> Users may access site information to learn about or purchase our marketing services.
      </p>
      <p className="mb-4">
        <strong>Orion Networking Platform:</strong> Users may access Orion’s features, including eCards, network analytics, and insights, solely for personal use. Unauthorized data scraping, resale, or reproduction of our content is prohibited.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Data Collection and Privacy</h2>
      <p className="mb-4">See our Privacy Policy for information on data collection and usage across Liquid GTM and Orion.</p>

      <h2 className="text-xl font-semibold mb-2">4. Electronic Communications</h2>
      <p className="mb-4">Communications may be delivered via email or through notices posted on our site. Electronic agreements meet any legal requirements for written communication.</p>

      <h2 className="text-xl font-semibold mb-2">5. Warranties and Disclaimers</h2>
      <p className="mb-4">All information, content, products, and services are provided on an “as is” basis. Liquid GTM disclaims warranties of any kind, including fitness for a particular purpose.</p>

      <h2 className="text-xl font-semibold mb-2">6. Liability Limitations</h2>
      <p className="mb-4">We are not liable for any damages arising from the use or inability to use our site or services.</p>

      <h2 className="text-xl font-semibold mb-2">7. Indemnification</h2>
      <p className="mb-4">Users agree to indemnify Liquid GTM and Orion for any violations of these Terms.</p>

      <h2 className="text-xl font-semibold mb-2">8. LinkedIn API Compliance</h2>
      <p className="mb-4">Orion complies with LinkedIn’s data usage policies. Data obtained through LinkedIn will only be accessed with user permission and will be used solely for networking and engagement purposes.</p>

      <h2 className="text-xl font-semibold mb-2">9. Intellectual Property</h2>
      <p className="mb-4">All content on our sites is protected under copyright law. Unauthorized use of our trademarks and intellectual property is prohibited.</p>

      <h2 className="text-xl font-semibold mb-2">10. Jurisdiction</h2>
      <p className="mb-4">These Terms are governed by the laws of Illinois. Any disputes will be resolved in Illinois courts.</p>

      <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
      <p className="mb-4">For questions, contact us at info@liquidgtm.com.</p>
      </div>
      </div>
  );
}

export default Terms;
