import React, { useEffect, useRef } from 'react';

function ManifestoModal({ onClose }) {
  const modalRef = useRef(null);

  // Close modal when clicking on the overlay (background)
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Handle "Escape" key to close modal
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  // Trap focus within modal and set focus on the modal when it opens
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Lock background scroll
    modalRef.current.focus();

    return () => {
      document.body.style.overflow = 'auto'; // Restore background scroll
    };
  }, []);

  return (
    <div
      onClick={handleOverlayClick}
      onKeyDown={handleKeyDown}
      tabIndex="-1"
      ref={modalRef}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl w-full overflow-y-auto max-h-[80vh] focus:outline-none">
        <h2 id="modal-title" className="text-3xl font-bold mb-4 text-center text-custom-turquoise">
          The Orion Manifesto
        </h2>
        
        <p className="text-lg font-semibold text-gray-800 mt-4">People matter.</p>
        <p className="text-gray-700 mb-4">
          More specifically, <strong>YOUR</strong> people matter.
        </p>

        <p className="text-gray-700 mb-4">
          Think about how much time you spend each day eating, grooming yourself, reading, working, 
          exercising, driving, etc. These things are probably the majority of your day.
        </p>

        <p className="text-gray-700 mb-4">
          Yet how much time do you spend giving attention to the people you’ve worked with? Sometimes 
          these are people you spent years with, often for more hours in a day than your own family. 
          Unless you need something specifically, and aside from clicking “like” and “celebrate” 
          buttons on LinkedIn, the answer is probably near zero.
        </p>

        <p className="text-gray-700 mb-4">
          When is the last time you called a connection in your network to help <strong>THEM</strong>, 
          not yourself? When was the last time you sent a business connection a personalized note or 
          card to genuinely congratulate, commend, or console them?
        </p>

        <p className="text-gray-700 mb-4">
          I’ve made this mistake over and over in my career. I truly care about the people that I’ve 
          worked with. They have touched my life in many ways. I still think about people I worked with 
          20 years ago regularly, even though I may not have spoken with them since then.
        </p>

        <p className="text-gray-700 mb-4">
          We are all busy. And networking is just not a daily habit for nearly all of us. It’s an 
          afterthought, if any thought is given to it at all.
        </p>

        <p className="text-gray-700 mb-4 font-semibold">Why is that?</p>

        <p className="text-gray-700 mb-4">
          Well firstly, networking takes time and energy, and we have a limited amount of both.
        </p>

        <p className="text-gray-700 mb-4">
          It also takes organization and process, which there is just no easy way to maintain on an 
          ongoing basis for most of us.
        </p>

        <p className="text-gray-700 mb-4">
          Sure, we could manually note the contacts in our network with whom we would like to stay in 
          touch and set up recurring calendar appointments to reach out to them. But how many of us do 
          that?
        </p>

        <p className="text-gray-700 mb-4 font-semibold">Personally, I do it for about 10 people.</p>

        <p className="text-gray-700 mb-4">
          I have nearly 4000 LinkedIn connections, the vast majority of which I know personally.
        </p>

        <p className="text-gray-700 mb-4 font-semibold">10/4000.</p>

        <p className="text-gray-700 mb-4">
          That is my ratio.
        </p>

        <p className="text-gray-700 mb-4">
          It’s embarrassing because I do care for and think of way more of my network than that. So I 
          have to ask myself, why am I not doing anything about it?
        </p>

        <h3 className="text-2xl font-bold mt-6 mb-4 text-custom-turquoise">That is where Orion comes in.</h3>

        <p className="text-gray-700 mb-4">
          Orion is a force-multiplying tool that helps you value your network. It guides you through 
          categorizing your connections and engaging with them at the appropriate intervals to ensure 
          you stay in touch.
        </p>

        <p className="text-gray-700 mb-4">
          Initially, that is all Orion will do: organize you and provide the insights to stay in touch 
          with the professional connections you care about most.
        </p>

        <p className="text-gray-700 mb-4">
          Orion will email you daily with recommended actions for engaging with the more important 
          parts of your LinkedIn network and make it easy to take those actions in a few minutes of 
          your day.
        </p>

        <p className="text-gray-700 mb-4 font-semibold">That’s it: engage your most important connections for a few minutes per day to achieve your goals.</p>

        <p className="text-gray-700 mb-4">
          I want everyone to take advantage of this, which is why the freemium layer of Orion will 
          always be enormous. Most professionals will be able to engage their entire networks on a 
          foundational level without paying anything.
        </p>

        <p className="text-gray-700 mb-4">
          Of course, Orion has to be a business, and there will be premium features. But they will be 
          features many of you will want to buy after the tool has demonstrated its value. Not features 
          you need to buy to get value from the tool.
        </p>

        <p className="text-gray-700 mb-4">
          Orion is not a tool for only sales and marketing people. It is intended for all professionals 
          where networking is important. This could include IT professionals, real estate agents, 
          accountants, lawyers, doctors, academics, recruiters, etc. Basically anyone who cares about 
          their network.
        </p>

        <p className="text-gray-700 mb-4 font-semibold">
          Orion goes live on December 1st, 2024. It will be simple and will help 
          you strengthen your connections immediately upon using the tool.
        </p>

        <p className="text-gray-700 mb-4">
          I could use your help however. If you’re interested in being notified when it goes live or 
          even getting some sneak peeks at what we’re developing, then sign up below. I’ll provide 
          weekly updates until we go live.
        </p>

        <p className="text-gray-700 mb-4">Here’s to making the best of both of our networks!</p>

        <p className="text-gray-700 mb-4 font-semibold">Cheers,</p>
        <p className="text-gray-700 mb-4">Phil Alampi<br />Founder</p>

        <p className="text-gray-700 italic">
          P.S. Orion was named after the prominent star constellation, Orion the Hunter, symbolizing 
          a series of powerful connections that together make up an impactful presence in our world. 
          It has always been my personal favorite constellation, ever since I first saw it as a kid.
        </p>

        <button
          onClick={onClose}
          className="bg-custom-gray text-white px-6 py-2 rounded-full mt-6 hover:bg-gray-700 transition focus:outline-none focus:ring-2 focus:ring-custom-turquoise"
          aria-label="Close manifesto modal"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ManifestoModal;
