import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="w-full bg-black text-gray-400 py-4 px-8 text-center flex items-center justify-between text-sm">
      <div className="space-x-4">
        {/* Link to Terms of Use */}
        <Link to="/terms" className="text-gray-400 hover:text-white">
          Terms
        </Link>

        {/* Link to Privacy Policy */}
        <Link to="/privacy" className="text-gray-400 hover:text-white">
          Privacy
        </Link>
      </div>
      
      <div className="text-gray-500">&copy; Liquid GTM LLC</div>

      <div className="flex items-center space-x-4">
        <span className="text-gray-500">Created by Phil Alampi</span>

        {/* LinkedIn Icon */}
        <a href="https://www.linkedin.com/in/philalampi/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>

        {/* X.ai Icon */}
        <a href="https://x.com/PhilAlampi1" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
