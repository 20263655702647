import React, { useState, useEffect } from 'react';
import SignupForm from './SignupForm';

function IntroSection({ onOpenManifesto }) {
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Detect window resize to handle mobile/desktop transitions
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="relative h-screen flex flex-col justify-center text-left text-white px-8 landscape-adjustment">
      
      {/* Conditionally render video or fallback image */}
      {isMobile ? (
        <img
          src="/stars-fallback.jpg" // Fallback image for mobile
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover z-0"
        />
      ) : (
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover z-0"
        >
          <source src="/stars.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {/* Overlay for darkening the background */}
      <div className="absolute inset-0 bg-black opacity-25 z-10"></div>

      {/* Main Content */}
      <div className="relative z-20 max-w-7xl space-y-3 pl-[5vw] md:pl-[8vw] lg:pl-[8vw]">
        <h1 className={`font-bold ${isMobile ? 'text-[#00a084]' : 'text-transparent bg-clip-text bg-gradient-to-r from-[#1c3f60] via-[#00a084] to-[#00bfa6]'} text-[10vw] sm:text-[8vw] md:text-[6vw] lg:text-[6vw] leading-tight sm:leading-[0.9] md:leading-[1.1] pb-1`}>
          Value your network
        </h1>
        <p className="text-[3.5vw] sm:text-[4vw] md:text-[2.5vw] lg:text-[2vw] leading-tight sm:mt-2 subheading">
          Harness the power of your professional connections to reach your goals faster with smarter, strategic networking.
        </p>
        <br />
        {/* Manifesto Preview with Fade-Out Effect */}
        <div className="text-[3vw] sm:text-[3.5vw] md:text-[1.5vw] lg:text-[1.2vw] text-gray-300 pt-[2vw] sm:pt-1 content-wrapper">
          <p className="text-[#ffd166] not-italic leading-relaxed">
            <strong>The Orion Manifesto</strong>
          </p>
          <p className="italic leading-snug">
            People matter. More specifically, <strong>YOUR</strong> people matter. Think about how much time you spend each day eating, grooming yourself, reading, working, exercising, driving, etc. These things are probably the majority of your day. 
          </p>
          <br />
          <p className="italic leading-snug fade-out-text">
            Yet how much time do you spend giving attention to the people you’ve worked with? Sometimes…
          </p>
          <div className="mt-2">
            <button
              onClick={onOpenManifesto}
              className="mt-4 bg-gray-300 text-black px-3 py-1 rounded-lg text-xs sm:text-sm hover:bg-gray-400 focus:outline-none button"
            >
              Read More
            </button>
          </div>
        </div>
      </div>

      {/* Floating Email Signup Form */}
      <div className={`fixed ${isMobile ? 'bottom-6 left-1/2 transform -translate-x-1/2' : 'bottom-14 right-16 md:bottom-10 md:right-12 lg:bottom-12 lg:right-14'} flex items-center space-x-2 z-30 email-signup-form`}>
        <SignupForm />
        {error && <p className="text-sm text-red-500 ml-4">{error}</p>}
        {status && <p className="text-sm text-gray-300 ml-4">{status}</p>}
      </div>

      {/* Fade-out CSS effect */}
      <style>{`
        .fade-out-text {
          position: relative;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
          -webkit-mask-image: linear-gradient(180deg, #fff, transparent);
          mask-image: linear-gradient(180deg, #fff, transparent);
          display: inline;
        }
      `}</style>
    </div>
  );
}

export default IntroSection;
