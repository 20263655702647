import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { IntroSection, ManifestoModal } from './components/HomePage';
import { NavBar, Footer, Privacy, Terms } from './components/Shared';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function AppComponent() {
  const [showModal, setShowModal] = useState(false);

  return (
    <Router>
      <div className="bg-gray-100 min-h-screen">
        <ScrollToTop />

        {/* Navbar */}
        <NavBar />

        {/* Routes for main homepage, Terms, and Privacy */}
        <Routes>
          {/* Home Route */}
          <Route
            path="/"
            element={
              <>
                {/* Main Sections for Homepage */}
                <IntroSection onOpenManifesto={() => setShowModal(true)} />
                
                {/* Manifesto Modal */}
                {showModal && <ManifestoModal onClose={() => setShowModal(false)} />}
              </>
            }
          />

          {/* Terms and Privacy Routes */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        {/* Footer */}
        <Footer />
      </div>
      </Router>
  );
}

export default AppComponent;
