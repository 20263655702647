import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // Handle navbar visibility based on scroll direction, only for larger screens
  const handleScroll = () => {
    if (window.innerWidth > 768) { // Only apply scroll behavior for larger screens
      if (window.scrollY > lastScrollY) {
        setIsVisible(false); // Hide navbar on scroll down
      } else {
        setIsVisible(true); // Show navbar on scroll up
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-transform duration-300 ${
        isVisible ? 'transform translate-y-0' : 'transform -translate-y-full'
      } flex justify-between items-center p-0.1`}
    >
      {/* Responsive Logo - Fixed at top-left on mobile only */}
      <div className={`${
        window.innerWidth < 768 ? 'fixed top-2 left-4' : 'ml-4 sm:ml-6 md:ml-8 lg:ml-10 xl:ml-10'
      } -mt-3 sm:mt-3 lg:mt-2 xl:mt-0`}>
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/Orion_Logo_Transp.png`}
            alt="Orion Logo"
            className="logo w-auto h-[24vw] sm:h-[15vw] md:h-[12vw] lg:h-[12vw] xl:h-[10vw]"
          />
        </Link>
      </div>

      {/* Placeholder for additional navbar items */}
      <div className="pr-6">
        {/* Add any additional nav links or buttons here if needed */}
      </div>
    </nav>
  );
}

export default NavBar;
